import logo from './smak3.png';
import logo2 from './footer.png';
import logo3 from './footer2.png';
import logo4 from './footer3.png';
import logo5 from './snadbox.png';
import HubspotForm from 'react-hubspot-form';
import Button from '@mui/material/Button'
import './App.css';
import Container from '@mui/material/Container';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';






library.add(fab);

function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://smakfoods.de">
        HEJ SMAK    </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



function App() {
  return (

    
    <div className="App">
      <header className="App-header">
      <Container maxWidth="lg">
      

        
        <img src={logo} className='App-logo' alt="logo" />

 
          {/* 
        <Typography variant="body2" color="white" align="center">

   
        <p>hej hej, wir sind bald soweit</p>
        <p>Trage dich gerne als "Early-SMAK-Fan" in unser Kontaktformular ein.</p>
        <p>Kontakt : info@smakfoods.de</p>
      
        </Typography>
*/}
    
              {/* <div className='margincontainer2'>
              <a href="https://instagram.com/smakfoods.de?igshid=MzRlODBiNWFlZA==" target="_blank" className="icons2">
                <FontAwesomeIcon icon={['fab', 'instagram']} size="s" color="white" />

              </a>
              </div> */}
              {/* <div>
              <Typography variant="body2" color="white" align="center">
                SMAK is loading...
                </Typography>
              <CircularProgress color="inherit" />
    </div> */}
<div className='margincontainer2'>

<Button variant="contained" href="https://share-eu1.hsforms.com/1a699BFkQQUiyQbrCls75rQ2b898i">
  CONTACT US
</Button>
</div>



  {/* <Button variant="contained" href="mailto:info@smakfoods.de">
  mail
</Button> */}
<div className="gallery">
      <img src={logo2} alt="Bild 1" className='App-logo-small'/>
      <img src={logo3} alt="Bild 2" className='App-logo-small'/>
      <img src={logo4} alt="Bild 3" className='App-logo-small' />
      <img src={logo5} alt="Bild 4" className='App-logo-small' />
      
      <a href="https://instagram.com/smakfoods.de?igshid=MzRlODBiNWFlZA==" target="_blank">
                <FontAwesomeIcon icon={['fab', 'instagram']} size="s" color="white" className='App-logo-small'/>
                </a>


    </div>

      </Container>
      

      </header>

     

  





    </div>
  );
}

export default App;
